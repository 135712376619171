import posthog from "posthog-js";
const createAliasId = (userId) => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case "production":
      return userId;
    default:
      return `${userId}-testing`;
  }
};

const posthogAddUserDetail = (type, value, userId = null) => {
  posthog.identify(userId ? createAliasId(userId) : posthog.get_distinct_id());
  posthog.people.set(type, value);
};
const posthogAddUserDetailsBulk = (leadDetails, userId = null) => {
  posthog.identify(userId ? createAliasId(userId) : posthog.get_distinct_id());
  posthog.people.set(leadDetails);
};

const posthogAddAlias = (userId) => {
  let alias = createAliasId(userId);
  window.__posthog.identify(alias);
};
export { posthogAddUserDetail, posthogAddUserDetailsBulk, posthogAddAlias };
