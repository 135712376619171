import axios from "axios";
import { getTimezone } from "../../helpers/getTimezone";
// import {
//   mixpanelAddAlias,
//   mixpanelAddUserDetailsBulk,
// } from "../../helpers/mixpanelAddUser";
import {
  posthogAddAlias,
  posthogAddUserDetailsBulk,
} from "../../helpers/posthogAddUser";
import {
  OTPStatusAttributes,
  // loginToBuyAttributes,
} from "../../helpers/MoengageAttributeCreators";
import moengageEvent from "../../helpers/MoengageEventTracking";
import { openInNewTab } from "../../helpers/openInNewTab";
import { sortProfiles } from "../../helpers/dashboardHelperFunctions";
import { getTeacherProfiles } from "../../helpers/trialHelpers";
const colors = {
  Communication: { light: "#EDFCFF", dark: "#83DEF0" },
  "Visual Arts": { light: "#DCCCFF", dark: "#8469C0" },
  Music: { light: "#FFEDC8", dark: "#FFC142" },
  Workshops: { light: "#EDFCFF", dark: "#83DEF0", darker: "#3EBEFF" },
};
const courseLevelCount = (displayName) => {
  switch (displayName.toLowerCase()) {
    case "guitar":
      return 2;
    case "western vocals":
      return 2;
    case "stop motion animation":
      return 3;
    case "art":
      return 2;
    default:
      return 1;
  }
};

// ! Auth actions
const sendOtp = (body) => {
  let newOtpFlowBody = { ...body, otp_version: "2" };
  return (dispatch) => {
    axios
      .post(`${process.env.NEXT_PUBLIC_AUTH_API}/send_otp`, newOtpFlowBody)
      .then(() => {
        dispatch({
          type: "SEND_OTP",
          payload: newOtpFlowBody,
        });
      })
      .catch((e) => {
        alert(e.response?.data?.error?.message);
      });
  };
};
const loginWithOtp = (
  authOtpRequestBody,
  otp,
  countryCode,
  phoneNumber,
  shouldTriggerLoginBox,
  loginBoxHandler = () => {},
  triggeredByHandler = () => {},
  isSignup,
  userUpdate = null,
  isGames = null,
  childName = null,
  childAge = null,
  isFromCheckoutModal = null,
  isAffiliates = false,
  referralCode = ""
) => {
  const GA_COOKIE = document.cookie
    ?.split(";")
    ?.filter((item) => item?.split("=")[0]?.trim() === "_ga")[0]
    ?.split("=")[1];
  return (dispatch) => {
    let postBody = {};
    if (authOtpRequestBody.phone_no) {
      postBody = {
        client_id: GA_COOKIE || "",
        phone_no: authOtpRequestBody.phone_no,
        email: authOtpRequestBody.email || "",
        otp_version: "2",
        otp: otp,
        parent_name: authOtpRequestBody.parent_name,
        user: {
          full_name: authOtpRequestBody.parent_name,
        },
      };
    } else if (authOtpRequestBody.email) {
      postBody = {
        // phone_no: authOtpRequestBody.phone_no || "",
        client_id: GA_COOKIE || "",
        email: authOtpRequestBody.email,
        parent_name: authOtpRequestBody.parent_name,
        otp_version: "2",
        otp: otp,
        user: {
          full_name: authOtpRequestBody.parent_name,
        },
      };
    }
    if (window?.location.pathname.includes("/live")) {
      postBody.context = "join_class";
    }
    if (userUpdate) {
      if (childName) {
        userUpdate.student_profiles = [
          {
            age: childAge && childAge > 4 ? +childAge : 5,
            full_name: childName,
          },
        ];
      }

      postBody.user = userUpdate;
    } else if (childName) {
      postBody.user = {
        student_profiles: [
          {
            age: childAge && childAge > 4 ? +childAge : 5,
            full_name: childName,
          },
        ],
      };
    }
    if (isGames) {
      postBody.source = "games";
    }
    if (isAffiliates) postBody.login_type = "AFFILIATE";
    else {
      postBody.login_type = "REGULAR";
      postBody.referred_code = referralCode ?? "";
    }
    axios
      .post(`${process.env.NEXT_PUBLIC_AUTH_API}/login_by_otp`, postBody)
      .then((response) => {
        moengageEvent(
          "Otp_Status",
          OTPStatusAttributes(1, 4, countryCode === "+91" ? 1 : 2)
        );
        // !to close checkout modal after success of login
        dispatch(setCheckoutLoginModal(false));
        const data = response.data;
        let authToken = data.token;
        let userDetails = {
          fullName: data.user.name,
          id: data.user.id,
          phoneNumber: data.user.phone_no || `${countryCode}-${phoneNumber}`,
          email: data.user.email,
        };
        let referralDetails = {
          referralCode: data.user?.referral_code ?? "",
        };
        // ! add sortProfiles helper
        let profiles = data.user.profiles?.sort((a, b) => a.id - b.id) || [];
        let teacherProfiles = getTeacherProfiles(profiles);
        // let profiles = data.user.profiles;
        // ! add alias & identify after login
        // mixpanelAddAlias(data.user.id);
        posthogAddAlias(data.user.id);
        // ! whether the login box was used or not
        if (shouldTriggerLoginBox) {
          loginBoxHandler();
        }
        // ! if triggered by booking flows, move to next page automatically
        triggeredByHandler();
        // ! Send external_id to fb
        if (window?.fbq) {
          window.fbq("init", "701185977201709", {
            external_id: userDetails.id,
          });
        }
        // ! dispatch the attributes
        dispatch({
          type: "LOGIN_WITH_OTP",
          payload: {
            userDetails,
            profiles,
            teacherProfiles,
            authToken,
            hasVerifiedNumber: data.user.has_verified_phone,
            hasVerifiedEmail: data.user.has_verified_email,
            referralDetails,
          },
        });

        if (isFromCheckoutModal) openInNewTab("/checkout");

        return { authToken, userDetails };
      })
      .then(({ authToken, userDetails }) => {
        if (isSignup) {
          axios
            .patch(
              `${process.env.NEXT_PUBLIC_UPDATE_USER_API}${userDetails.id}`,
              {
                user: {
                  full_name: postBody.parent_name,
                  email: postBody.email,
                },
              },
              {
                headers: {
                  Authorization: authToken,
                  "X-SSUID": userDetails.id,
                },
              }
            )
            .then((res) => {
              let userDetails = {
                fullName: res.data.user.name,
                id: res.data.user.id,
                phoneNumber:
                  res.data.user.phone_no || `${countryCode}-${phoneNumber}`,
                email: res.data.user.email,
              };
              if (isGames) {
                let fullNameArray = userDetails.fullName.split(" ");
                let firstName = fullNameArray[0];
                let lastName = fullNameArray
                  .splice(1, fullNameArray.length - 1)
                  .join(" ");
                // mixpanelAddUserDetailsBulk(
                //   {
                //     $first_name: firstName,
                //     $last_name: lastName,
                //     $Parent_Name: postBody.parent_name,
                //     $Phone_Number: userDetails.phoneNumber,
                //     $phone: userDetails.phoneNumber,
                //     $Email: userDetails.email,
                //     $email: userDetails.email,
                //     $Child_Name: childName,
                //     $Child_Age: childAge,
                //     $Auth_User_ID: userDetails.id || "",
                //     $timezone: getTimezone(),
                //   },
                //   userDetails.id
                // );
                posthogAddUserDetailsBulk(
                  {
                    first_name: firstName,
                    last_name: lastName,
                    Parent_Name: postBody.parent_name,
                    Phone_Number: userDetails.phoneNumber,
                    phone: userDetails.phoneNumber,
                    Email: userDetails.email,
                    email: userDetails.email,
                    Child_Name: childName,
                    Child_Age: childAge,
                    Auth_User_ID: userDetails.id || "",
                    timezone: getTimezone(),
                  },
                  userDetails.id
                );
              }
              dispatch({
                type: "SET_USER_DETAILS",
                payload: userDetails,
              });
            })
            .catch((err) => console.log("new user signup err", err));
        }
      })
      .catch((e) => {
        moengageEvent(
          "Otp_Status",
          OTPStatusAttributes(0, 0, countryCode === "+91" ? 1 : 2)
        );
        if (e.response) {
          dispatch(setVerifyOTPClick(false));
          alert(e.response?.data?.error?.message);
        }
      });
  };
};

const loginWithUniformJunctionToken = (
  userDetails,
  profiles,
  authToken,
  hasVerifiedNumber,
  hasVerifiedEmail
) => {
  return {
    type: "LOGIN_WITH_OTP",
    payload: {
      userDetails,
      profiles,
      authToken,
      hasVerifiedNumber: hasVerifiedNumber,
      hasVerifiedEmail: hasVerifiedEmail,
    },
  };
};
const saveAuthToken = (data) => {
  return {
    type: "SAVE_AUTH_TOKEN",
    payload: data,
  };
};
const changeNumber = () => {
  return {
    type: "CHANGE_NUMBER",
  };
};
const setAuthSourcePage = (source) => {
  return {
    type: "SET_AUTH_SOURCE_PAGE",
    payload: source,
  };
};
const setAuthDestinationPage = (destination) => {
  return {
    type: "SET_AUTH_DESTINATION_PAGE",
    payload: destination,
  };
};
// ! courses actions
const getCourses = (coursesData) => {
  const filterHiddenCourses = coursesData?.filter(
    (courses) => courses?.is_hidden === false
  );
  let allCourses = filterHiddenCourses?.map((course) => {
    return {
      courseId: course.identifier,
      rating: course.rating,
      urlSlug: course.url_slug,
      gamesCount: course.games_count,
      courseStatus: course.is_live ? "ACTIVE" : "UPCOMING",
      courseName: course.name,
      displayName: course.display_name,
      vertical: course.category_name.name,
      courseLevel: course.level,
      numberOfClasses: course.num_classes,
      sessionDuration: course.session_duration_minutes,
      priceINR: Number(course.price_inr.split(".")[0]),
      priceUSD: Number(course.price_usd.split(".")[0]),
      priceAED: Number(course.price_aed.split(".")[0]),
      oldPriceINR: Number(course?.price_inr_old?.split(".")[0]),
      oldPriceUSD: Number(course?.price_usd_old?.split(".")[0]),
      oldPriceAED: Number(course?.price_aed_old?.split(".")[0]),
      minAge: course.min_age,
      maxAge: course.max_age,
      classRatio: course.class_size,
      showOutside: course.show_outside,
      showOutsideMinAge: course.show_outside_min_age,
      showOutsideMaxAge: course.show_outside_max_age,
      courseType: "Regular",
      coursePriority: course.priority,
      verticalThemeColorLight: colors[course.category_name].light,
      verticalThemeColorDark: colors[course.category_name].dark,
      courseLiner: course.course_one_liner,
      courseContent: course.pitch.split(".")[0],
      courseCurrency: "INR",
      courseLevelCount: courseLevelCount(course.display_name),
      courseTags: course.tags,
      numberOfHomeActivities: course.number_of_home_activities,
      classSize: Number(course.class_size),
      courseUsp: course.usp_of_course,
      fullDescription: course.full_description,
      pitch: course.pitch,
      enumPitch: course.enum_pitch,
      shortPitch: course.short_pitch,
      socialProof: course.social_proof,
      curriculumLiner: course.curriculum_liner,
      curriculumOutcomes: course.curriculum_outcomes,
      curriculumSessions: course.curriculum_sessions,
      showcaseSectionHeader: course.showcase_section_header,
      showcaseSectionContent: course.showcase_section_content,
      showcaseData: course.showcase_data,
      testimonialData: course.testimonial_data,
      expertDetails: course.expert_details,
      // ! moengage ids
      moHomepageCardId: course.homepage_card_id,
      moAllCoursesCardId: course.all_courses_card_id,
      moAddToCartCardId: course.add_to_cart_card_id,
    };
  });

  return {
    type: "SET_NEW_ALL_COURSES",
    payload: allCourses,
  };
};

const setMeetingDetails = (data) => {
  return {
    type: "SET_MEETING_DETAILS",
    payload: data,
  };
};
// ! Login Signup modals
const openSignup = () => {
  return {
    type: "SIGNUP_MODAL_OPEN",
  };
};
const openLogin = () => {
  return {
    type: "LOGIN_MODAL_OPEN",
  };
};
const openGuest = () => {
  return {
    type: "GUEST_MODAL_OPEN",
  };
};
const setTempPhoneNumber = (data) => {
  return {
    type: "TEMP_PHONE_NUMBER",
    payload: data,
  };
};
const setTempFullName = (data) => {
  return {
    type: "TEMP_FULL_NAME",
    payload: data,
  };
};
const setTempEmail = (data) => {
  return {
    type: "TEMP_EMAIL",
    payload: data,
  };
};
const setTriggeredBy = (data) => {
  return { type: "SET_AUTH_TRIGGERED_BY", payload: data };
};
const setProfiles = (data) => {
  return { type: "SET_PROFILES", payload: data };
};
const logoutUser = () => {
  return { type: "LOGOUT_USER" };
};
const setFreeLoginModalOpen = () => {
  return { type: "FREE_LOGIN_MODAL_OPEN" };
};

const setCheckoutLoginModal = (data) => {
  return { type: "CHECKOUT_LOGIN_MODAL", payload: data };
};

const setVerifyOTPClick = (data) => {
  return { type: "VERIFY_OTP_CLICK", payload: data };
};
const setFreeLogin = (
  phone,
  email = "",
  sendOtpBody,
  postLoginHandler = () => {},
  SendOtptoPhone = false
) => {
  return (dispatch) => {
    let getLoginDetails = (body) => {
      axios
        .post(process.env.NEXT_PUBLIC_FREE_LOGIN_API, body)
        .then((res) => {
          let data = res.data;
          let userDetails = {
            fullName: data.user.name,
            id: data.user.id,
            phoneNumber: data.user.phone_no,
            email: data.user.email,
          };
          let profiles = sortProfiles(data.user.profiles);
          let teacherProfiles = getTeacherProfiles(profiles);
          dispatch({
            type: "FREE_LOGIN",
            payload: {
              userDetails,
              profiles: profiles,
              teacherProfiles,
              authToken: data.token,
              hasVerifiedNumber: data.user.has_verified_phone,
              hasVerifiedEmail: data.user.has_verified_email,
              freeLoginModalOpen: false,
            },
          });
          // dispatch({ type: "FREE_LOGIN_MODAL_OPEN" });
          posthogAddAlias(data.user.id);
          postLoginHandler(teacherProfiles);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            let sendOtpBodyFor4Digits = { ...sendOtpBody, otp_version: "2" };
            axios
              .post(
                `${process.env.NEXT_PUBLIC_AUTH_API}/send_otp`,
                sendOtpBodyFor4Digits
              )
              .then(() => {
                dispatch({
                  type: "SEND_OTP",
                  payload: sendOtpBodyFor4Digits,
                });
              })
              .catch((e) => {
                alert(e.response?.data?.error?.message);
              });
            return;
          }
          // console.log("here", err);
          alert(err?.response?.data?.error?.message);
        });
    };
    if (SendOtptoPhone) {
      getLoginDetails({ phone_no: phone });
    } else {
      getLoginDetails({ email: email });
    }
  };
};
const setAuthFromSparkNative = (data) => {
  return { type: "SET_AUTH_FROM_SPARK_NATIVE", payload: data };
};

const setValidCountryCodes = (data) => {
  return {
    type: "SET_VALID_COUNTRY_CODE",
    payload: data,
  };
};

export {
  sendOtp,
  loginWithOtp,
  saveAuthToken,
  changeNumber,
  setMeetingDetails,
  openSignup,
  openLogin,
  openGuest,
  setTempPhoneNumber,
  setTempFullName,
  setTempEmail,
  setTriggeredBy,
  logoutUser,
  setProfiles,
  setFreeLoginModalOpen,
  setFreeLogin,
  setAuthSourcePage,
  setAuthDestinationPage,
  setVerifyOTPClick,
  setAuthFromSparkNative,
  setCheckoutLoginModal,
  loginWithUniformJunctionToken,
  setValidCountryCodes,
  getCourses,
};
