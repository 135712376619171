// ! all dashboard helper functions
import moment from "moment";
//! Function to get the time of the day
const getTimeOfDay = () => {
  var today = new Date();
  var time = today.getHours();
  // console.log(time);
  if (time >= 0 && time < 12) {
    return "Good Morning";
  } else if (time >= 12 && time < 16) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

// ! helper function to sort profile ids based on below contraints
const sortProfiles = (profiles) => {
  if (!profiles) return [];
  // ! filter upcoming class profiles
  // ! sort only for upcoming classes and paid classes
  const upComingClassProfiles = profiles
    .filter((res) => res.next_live_class !== null)
    .sort((res) => (res ? -1 : 1))
    .sort((a, b) => {
      return a?.next_live_class?.start_time - b?.next_live_class?.start_time;
    });

  // ! filter no upcoming class profiles.
  const nonUpcomingClassProfiles = profiles
    .filter((res) => res.next_live_class === null)
    .sort((res) => (res ? -1 : 1));

  // ! concat
  return upComingClassProfiles.concat(nonUpcomingClassProfiles);
};

//! Function to parse time to readable format
const timeParser = (startTime) => {
  return moment(startTime * 1000).format("Do MMM, h:mm A");
};

// ! upcoming class details string maker
const upcomingClassStringMaker = (upcomingClass) => {
  if (upcomingClass)
    return `Upcoming Storytelling class on ${timeParser(upcomingClass)}`;
  return "No Upcoming Classes";
};

export { getTimeOfDay, sortProfiles, upcomingClassStringMaker };
