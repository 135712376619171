import trialDetails from "../store/staticData/trialPageBookingV6.json";
const getUniqueCourseIds = (courseIds) => {
  let onlyCourseIds = courseIds.map((item) => item.split("|").shift());
  return new Array(...new Set(onlyCourseIds));
};
const getCourseTitles = (courseIds, type) => {
  let uniqueCourseIds = getUniqueCourseIds(courseIds);
  switch (type) {
    case "trial":
      return uniqueCourseIds.map((courseId) => {
        let found = trialDetails.find((item) =>
          item.unique_course_id.includes(courseId)
        );
        return found.ls_workshop_title;
      });
    default:
      return ["Exception - No courses found"];
  }
};

// ! function to filterout teacher profiles
const getTeacherProfiles = (profiles) => {
  const teacherProfiles = profiles?.filter(
    (profile) => profile.profile_type === "faculty"
  );
  return teacherProfiles ?? [];
};

const logGreen = (log, message = "") => {
  console.log(
    `%c ${log} - 🚀${message} `,
    "background: green; color: black; padding: 5px; font-size: 14px"
  );
};
const logRed = (log, message = "") => {
  console.log(
    `%c ${log}- 🚀${message} `,
    "background: red; color: black; padding: 5px; font-size: 14px"
  );
};
const logYellow = (log, message = "") => {
  console.log(
    `%c ${log}- 🚀${message} `,
    "background: yellow; color: black; padding: 5px; font-size: 14px"
  );
};

const getCourseName = (courseIdentifier) => {
  const found = trialDetails.find(
    (course) => course.unique_course_id === courseIdentifier
  );
  return found?.workshop_title ?? "";
};

export { logGreen, logRed, logYellow };

export { getCourseTitles, getTeacherProfiles, getCourseName };
